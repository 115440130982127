/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&display=swap');*/

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Neue';
    src: url('/fonts/NeueRoman.woff2') format('woff2'),
    url('/fonts/NeueRoman.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
}

/*@font-face {*/
/*    font-family: 'Neue';*/
/*    src: url('/fonts/NeueMedium.woff2') format('woff2'),*/
/*    url('/fonts/NeueMedium.ttf') format('ttf');*/
/*    font-weight: 500;*/
/*    font-style: normal;*/
/*}*/

@font-face {
    font-family: 'Neue';
    src: url('/fonts/NeueMedium.woff2') format('woff2'),
    url('/fonts/NeueMedium.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}


/* Safari 15 gray border bug fix */
/* https://nextjs.org/docs/api-reference/next/image */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading="lazy"] {
        clip-path: inset(0.6px)
    }
}

:root {
    --nav-height: 94px;
}

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, .04);
}

label[for],
button:not([disabled]) {
    @apply cursor-pointer;
}

svg {
    @apply block max-w-full;
}

.nav {
    @apply fixed top-0 left-0;
    @apply w-full z-40;
    @apply text-white;
    @apply transition-all duration-300;
}

.nav-scrolled {
    @apply bg-background-dark md:bg-transparent;
}

.black-nav .nav {
    @apply text-dark;
}

.black-nav .nav-scrolled {
    @apply bg-background-light md:bg-transparent;
}

.button {
    @apply relative;
    @apply inline-flex items-center font-medium;
    @apply border border-white/40 rounded-full;
    @apply will-change-transform;
    @apply overflow-hidden;
    @apply mouse-hover:text-dark border-white;
    @apply motion-safe:md:transition-all motion-safe:md:duration-300;
}

.black-nav .button {
    @apply border-dark/40;
    @apply mouse-hover:text-white mouse-hover:border-dark;
}

.button:after {
    content: '';
    @apply absolute top-0 -left-px w-0 h-full rounded-full;
    @apply bg-white;
    @apply motion-safe:transition-all motion-safe:md:duration-500;
    z-index: -1;
}

.black-nav .button:after {
    @apply bg-dark;
}

@media (hover: hover) {
    .button:hover:after {
        width: calc(100% + 2px);
    }
}

/*.black-nav .button {*/
/*    @apply mouse-hover:border-white;*/
/*}*/

.button-arrow {
    @apply will-change-transform;
    @apply motion-safe:transition-all motion-safe:md:duration-300;
}

@media (hover: hover) {
    .button:hover .button-arrow {
        @apply translate-x-1/3;
        @apply motion-safe:md:duration-500;
    }
}

.button-base {
    @apply text-base;
    @apply py-2.5 px-4;
}

.button-base .button-arrow {
    @apply ml-3;
    width: 17px;
}

.button-3xl {
    @apply text-3xl;
    @apply py-3 px-6;
}

.button-3xl .button-arrow {
    @apply ml-6;
    width: 25px;
}

.claim-wrapper {
    @apply flex relative;
    /*min-height: min(800px, 100vh);*/
    min-height: min(100vh, 600px);
    min-height: 100vh;
}

.claim-wrapper:before,
.claim-wrapper:after {
    /*content: '';*/
    @apply absolute left-0;
    @apply w-full h-[100px];
    @apply from-background-dark to-background-dark/0 z-10;
    @apply pointer-events-none;
}

.claim-wrapper:before {
    @apply top-0;
    @apply bg-gradient-to-b;
}

.claim-wrapper:after {
    @apply bottom-0;
    @apply bg-gradient-to-t;
}

.claim-wrapper:last-of-type:after {
    @apply hidden;
}

.claim {
    @apply text-5xl font-medium;
    @apply my-auto;
    @apply lg:text-6xl;
}

.text-gradient,
.claim b,
.claim a {
    background: linear-gradient(135deg, #5ABFE9 0%, #7033E2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 0.1ch;
    margin-bottom: -0.1ch;
}

.claim b {
    font-weight: inherit;
}

.claim a {
    @apply border-b-2 border-gradient-end transition-colors duration-200;
    @apply mouse-hover:border-gradient-start;
}

.claim-fadeout {
    @apply relative;
    @apply lg:whitespace-nowrap;
    @apply lg:overflow-hidden;
}

.claim-fadeout:after {
    content: '';
    @apply hidden lg:block;
    @apply absolute top-0 right-0;
    @apply w-1/4 h-full;
    @apply pointer-events-none;
    background: linear-gradient(270deg, rgba(var(--bg-r, 22), var(--bg-g, 22), var(--bg-b, 22), 1) 35.03%, rgba(var(--bg-r, 22), var(--bg-g, 22), var(--bg-b, 22), 0) 80.08%);
}

.circle-photo {
    @apply absolute top-1/2 left-1/2 bg-dark rounded-3xl overflow-hidden;
    width: calc(var(--radius) / 1.5);
    height: calc(var(--radius) / 1.5);
    transform: translate(-50%, -50%) rotate(var(--angle)) translate(calc(var(--radius, 0) * 1.2)) rotate(90deg);
    transition: all 1s ease;
}

.gallery-image {
    @apply lg:min-w-max;
}